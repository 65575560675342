// training mode:
// - the student can select words and press 'done' many times
// - after each press, the student gets feedback only for the selections made.
// quiz mode:
// - the student can select words and press 'done' only once
// - after the first press, the student gets feedback for the selections
// made, and for words that were missed.
<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" @done="gradeQuestion">
        <v-card-text style="min-width: 300px;">
            <p v-if="item.question" class="text-body-1 mb-2">
                <span v-html="markdown(item.question)"></span>
                <span class="text-grey-darken-1"> ({{ $tc('activity_preview.words', item.selectedWordsRequired.length) }})</span>
                <speak-text-icon :text="item.question" />
            </p>
            <v-row dense align="center">
                <span v-for="(word, index) in words" :key="index" @click="selectWord(index)"
                    :class="['word', getWordClass(index)]">
                    {{ word }}
                </span>
                <speak-text-icon :text="item.text" />
            </v-row>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import SpeakTextIcon from './SpeakTextIcon.vue';

export default {
    components: {
        ItemView,
        SpeakTextIcon
    },
    extends: ItemView,
    data() {
        return {
            doneClicked: false,
        }
    },
    computed: {
        words() {
            return this.item.text.split(/\s+/);
        },
        hasContentToShow() {
            return this.item.question !== "" || this.item.text !== "";
        },
    },
    watch: {
        // when the teacher changes the form, we "reset" the view
        'item.selectedWordsRequired': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
        'item.selectedWordsOptional': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
    },
    methods: {
        selectWord(index) {
            if (this.doneClicked) {
                if (this.isTrainingMode) {
                    this.doneClicked = false;
                    this.item.grade = null;
                } else {
                    return;
                }
            }

            this.playTapSound();
            const selectedWords = this.item.studentSelectedWords;

            if (!selectedWords.includes(index)) {
                selectedWords.push(index);
            } else {
                const indexInArray = selectedWords.indexOf(index);
                selectedWords.splice(indexInArray, 1);
            }
        },
        getWordClass(index) {
            if (!this.doneClicked && this.item.studentSelectedWords.includes(index)) {
                return 'word-selected';
            } else if (this.doneClicked) {
                var wordSelectedByStudent = this.item.studentSelectedWords.includes(index);
                var wordRequired = this.item.selectedWordsRequired.includes(index);
                var wordOptional = this.item.selectedWordsOptional.includes(index);
                if (this.isTrainingMode) {
                    // in training mode, we only provide feedback
                    // for words that are selected by the student!
                    if (wordSelectedByStudent &&
                        (wordRequired || wordOptional)) {
                        return 'word-correct';
                    } else if (wordSelectedByStudent) {
                        return 'word-incorrect';
                    }
                } else {
                    // in quiz mode, we provide feedback for selected words
                    // and for words that are required but not selected
                    if (wordSelectedByStudent &&
                        (wordRequired || wordOptional)) {
                        return 'word-correct';
                    } else if (wordRequired) { // word is required but not selected
                        return 'word-correct-missing';
                    } else if (wordSelectedByStudent) { // word is not required but is selected
                        return 'word-incorrect';
                    }

                }
            }
            return '';
        },
        handleChangeInQuestionForm() {
            this.item.grade = null; // reset grade
            this.item.studentSelectedWords = []; // reset student answer
            this.doneClicked = false;
            this.item.numAnswered = 0;
        },
        gradeQuestion() {
            if (this.item.studentSelectedWords.length === 0) {
                return;
            }
            this.doneClicked = true;
            this.item.numAnswered++;

            // Calculate recall and precision
            const truePositives = this.item.studentSelectedWords.filter(index =>
                this.item.selectedWordsRequired.includes(index) || this.item.selectedWordsOptional.includes(index)).length;
            const falsePositives = this.item.studentSelectedWords.length - truePositives;
            const falseNegatives = this.item.selectedWordsRequired.length - this.item.studentSelectedWords.filter(index => this.item.selectedWordsRequired.includes(index)).length;

            const precision = truePositives / (truePositives + falsePositives);
            const recall = truePositives / (truePositives + falseNegatives);

            // Calculate F1 score
            this.item.grade = 0;
            if (precision + recall > 0) {
                this.item.grade = 2 * ((precision * recall) / (precision + recall));
            }
            // multiply by 100 and round to nearest integer
            this.item.grade = Math.round(this.item.grade * 100);

            this.playDoneSound();
        },
    }
}
</script>
<style scoped>
.word {
    margin-right: 5px;
    cursor: pointer;
    font-size: 16px;
    color: grey;
    /* adjust as needed */
}

.word-selected {
    color: blue;
}

.word-correct {
    color: green;
}

.word-correct-missing {
    color: #A5D6A7;
    /* green-lighten-3 */
}

.word-incorrect {
    color: red;
}
</style>