<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-text-field @focus="setActiveItem()" class="mb-3" variant="underlined" :label="$t('activity_editor.question')" v-model="item.question"
        hide-details autofocus></v-text-field>
      <v-textarea @focus="setActiveItem()" class="mb-3" variant="underlined" :label="$t('activity_editor.text')" v-model="item.text" rows="1"
        hide-details auto-grow></v-textarea>
      <v-switch @focus="setActiveItem()" v-if="item.text" v-model="mode"
        :label="mode ? $t('activity_editor.mark_required') : $t('activity_editor.mark_optional')" hide-details></v-switch>
      <v-row dense>
        <span v-for="(word, index) in words" :key="index" :class="['word',
          { 'word-required': item.selectedWordsRequired.includes(index) },
          { 'word-optional': item.selectedWordsOptional.includes(index) }]" @click="selectWord(index)">
          {{ word }}
        </span>
      </v-row>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'MarkWordsQuestionForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
  data() {
    return {
      mode: true, // true for 'required', false for 'optional'
    };
  },
  computed: {
    words() {
      return this.item.text.split(/\s+/);
    }
  },
  methods: {
    selectWord(index) {
      const selectedWords = this.mode ? this.item.selectedWordsRequired : this.item.selectedWordsOptional;
      const otherSelectedWords = this.mode ? this.item.selectedWordsOptional : this.item.selectedWordsRequired;

      if (!selectedWords.includes(index)) {
        selectedWords.push(index);
        const indexInOtherArray = otherSelectedWords.indexOf(index);
        if (indexInOtherArray !== -1) {
          otherSelectedWords.splice(indexInOtherArray, 1);
        }
      } else {
        const indexInArray = selectedWords.indexOf(index);
        selectedWords.splice(indexInArray, 1);
      }
    }
  }
}
</script>
<style scoped>
.word-required {
  color: green;
}

.word-optional {
  color: orange;
}

.word {
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
  /* adjust as needed */
}
</style>